<template>
  <div>
    <section id="cart">
      <Header/>
      <div class="inner">
        <div class="breadcrumbs">
          購物車 <span class="txt-lightgrey">> 填寫資料與付款 > 訂購完成</span>
        </div>

          <div class="cart_block mb50">
            <h4 class="txt-bold">購物車明細</h4>
            <div class="non_cart_area txt-center">

              <img
                src="@/assets/img/shopping/icon_car-0.svg"
                style="width: 140px">

              <h5 class="txt-grey">購物車內目前沒有商品</h5>

              <router-link
                :to="{
                  name: 'Product',
                  params: { category: 1 },
                  query: { productCategory: 1 },
                }"
                class="btn btn-orange_white_line"
                style="width:200px; border: 1px solid #FC8D04">
                <img src="@/assets/img/icon/icon_next-o.svg">
                <p>
                  前往選購
                </p>
              </router-link>
            </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Header from '@/components/Header.vue';

import '@/assets/scss/cart.scss';

export default {
  name: 'CartEmpty',
  components: {
    Header,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    ...mapState([
      'isLogin',
    ]),
  },
  mounted() {
    if (!this.isLogin) {
      this.$customSWAL({
        icon: 'error',
        title: '錯誤',
        text: '未登入會員',
        confirmButtonText: '確定',
        thenFunc: () => {
          this.$router.push('/');
        },
      });
    }
  },
};
</script>
